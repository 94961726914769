import { Link } from "gatsby"
import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const publicAndBusinessGrid = (labels, isSmallScreen, pagePaths) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/interiors/interior-z-office-lobby-hallway.jpg",
        alt: labels.interiorsInteriorZOfficesHallwayLobbyAlt,
        imgTitle: labels.interiorsInteriorZOfficesLobbyImgTitle,
        text: labels.interiorsInteriorZOfficesText,
        textContainerClass: "interiors-interior-z-offices-text-container",
        textClass: "interiors-interior-z-offices-text"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/interior-z-office-lobby.jpg",
        alt: labels.interiorsInteriorZOfficesLobbyAlt,
        imgTitle: labels.interiorsInteriorZOfficesLobbyImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/interior-z-office-hallway.jpg",
        alt: labels.interiorsInteriorZHallwayAlt,
        imgTitle: labels.interiorsInteriorZHallwayImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: true,
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/interior-z-lighting-design-lobby.jpg",
        alt: labels.interiorsInteriorZLightingDesignLobbyAlt,
        imgTitle: labels.interiorsInteriorZLightingDesignLobbyImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/interiors/interior-z-lighting-design-box.jpg",
        alt: labels.interiorsInteriorZLightingDesignBoxAlt,
        imgTitle: labels.interiorsInteriorZLightingDesignBoxImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 49.25 : 100,
        src: isSmallScreen
          ? "/images/interiors/the-best-life-wall-tables.jpg"
          : "/images/interiors/the-best-life.jpg",
        containerClass: "img-container interiors-the-best-life-img-container",
        fit: isSmallScreen ? "contain" : "fill",
        alt: isSmallScreen
          ? labels.interiorsTheBestLifeBarAndNightClubWallTablesAlt
          : labels.interiorsTheBestLifeBarAndNightClubAlt,
        imgTitle: isSmallScreen
          ? labels.interiorsTheBestLifeBarAndNightClubWallTablesImgTitle
          : labels.interiorsTheBestLifeBarAndNightClubImgTitle,
        text: labels.interiorsTheBestLifeBarAndNightClubText,
        textContainerClass:
          "interiors-the-best-life-bar-and-night-club-text-container",
        textClass: "interiors-the-best-life-bar-and-night-club-text"
      },
      {
        type: "image",
        weight: isSmallScreen ? 49.25 : 0,
        src: "/images/interiors/the-best-life-lighting-dark.jpg",
        alt: labels.interiorsTheBestLifeBarAndNightClubDarkAlt,
        imgTitle: labels.interiorsTheBestLifeBarAndNightClubDarkImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/interiors/conceptual-solutions-drawings.jpg",
        containerClass:
          "img-container interiors-conceptual-solutions-img-container",
        fit: isSmallScreen ? "contain" : "fill",
        alt: labels.interiorsConceptualSolutionsAlt,
        imgTitle: labels.interiorsConceptualSolutionsImgTitle,
        text: labels.interiorsConceptualSolutionsText,
        textContainerClass: "interiors-conceptual-solutions-text-container",
        textClass: "interiors-conceptual-solutions-text"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: true,
    grid: [
      {
        type: "image",
        weight: 35,
        src: "/images/interiors/3x-club-lightshow.jpg",
        containerClass:
          "img-container interiors-3x-club-lightshow-img-container",
        alt: labels.interiors3XClubLightshowAlt,
        imgTitle: labels.interiors3XClubLightshowImgTitle,
        text: labels.interiors3XClubText,
        textContainerClass: "interiors-3x-club-text-container",
        textClass: "interiors-3x-club-text"
      },
      {
        type: "image",
        weight: 63.5,
        src: "/images/interiors/3x-club-gif.gif",
        containerClass: "img-container interiors-3x-club-gif-img-container",
        alt: labels.interiors3XClubGifAlt,
        imgTitle: labels.interiors3XClubGifImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 70,
        src: "/images/interiors/boutique-hotel-drawing-floor.jpg",
        containerClass:
          "img-container interiors-boutique-hotel-drawing-floor-img-container",
        alt: labels.interiorsBoutiqueHotelDrawingAlt,
        imgTitle: labels.interiorsBoutiqueHotelDrawingImgTitle,
        text: labels.interiorsBoutiqueHotelText,
        textContainerClass: "interiors-boutique-hotel-text-container",
        textClass: "interiors-boutique-hotel-text"
      },
      {
        type: "image",
        weight: 28.5,
        src: "/images/interiors/boutique-hotel-drawing-furniture.jpg",
        containerClass:
          "img-container interiors-boutique-hotel-drawing-furniture-img-container",
        alt: labels.interiorsRoom1DrawingAlt,
        imgTitle: labels.interiorsRoom1DrawingImgTitle,
        text: labels.interiorsRoom1Text,
        textContainerClass: "interiors-room-1-text-container",
        textClass: "interiors-room-1-text"
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="interiors-public-and-business-other-text-container">
            <p>{labels.interiorsOtherText}:</p>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.privateInteriors}
            >
              <p>{labels.interiorsPrivateOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.gameOfLightsInteriors}
            >
              <p>{labels.interiorsGameOfLightsOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.backRoomInteriors}
            >
              <p>{labels.interiorsBackRoomOtherText}</p>
            </Link>
          </div>
        )
      }
    ]
  }
]

export default function PublicAndBusiness({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.publicAndBusinessInteriors}
      description={labels.publicAndBusinessInteriorsDescription}
      keywords={labels.publicAndBusinessInteriorsKeywords}
      ogUrl={paths(labels).publicAndBusinessInteriors}
      ogImage="/images/og-images/public-and-business-interiors.jpg"
      headerPadding={isSmallScreen}
    >
      <ResponsiveImageGrid
        grid={publicAndBusinessGrid(labels, isSmallScreen, paths(labels))}
        margin={false}
      />
    </Page>
  )
}
